<template>
  <MembersTpUsers />
</template>

<script setup>
import MembersTpUsers from '@/components/views/Users/VMembersTpUsers'
import { defineComponent } from 'vue'

defineComponent({
  MembersTpUsers
})
</script>
