export const USER_EXPAND_TABLE = 'expand=roles'

export const USER_TABLE_HEAD = [
  {
    name: 'ФИО',
    value: 'name',
    filter: true,
    decorator: {
      name: 'link'
    }
  },
  {
    name: 'Логин',
    value: 'login',
    filter: true,
    decorator: {
      name: 'link'
    }
  },
  {
    name: 'E-mail',
    value: 'email',
    filter: true
  },
  {
    name: 'Компания',
    value: 'organization_name',
    filter: true
  },
  {
    name: 'Аккредитация',
    value: 'accreditation',
    filter: true,
    decorator: {
      name: 'boolean',
      path: ['roles', 0, 'id'],
      handler: val => val !== 2
    }
  },
  {
    name: 'Дата регистрации',
    value: 'created_at',
    filter: true
  }
]
